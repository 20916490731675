var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1a16af8ae4fbaf320fedc1f7c144a331219469d8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BaseTransportOptions, Transport, TransportRequestExecutor } from '@sentry/types/types/transport';

function makeFetchTransport(
  options: BaseTransportOptions & { headers?: HeadersInit; fetchOptions?: object }
): Transport {
  const makeRequest: TransportRequestExecutor = (request) => {
    const controller = new AbortController();

    const timeout = 1000;
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, timeout);

    const requestOptions: RequestInit = {
      body: request.body,
      method: 'POST',
      referrerPolicy: 'origin',
      headers: options.headers,
      ...options.fetchOptions,
      signal: controller.signal,
    };

    return fetch(options.url, requestOptions)
      .then((response) => {
        return {
          statusCode: response.status,
          headers: {
            'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
            'retry-after': response.headers.get('Retry-After'),
          },
        };
      })
      .finally(() => {
        clearTimeout(timeoutId);
      });
  };

  return Sentry.createTransport(options, makeRequest);
}

Sentry.init({
  dsn: 'https://7741a3516ee507e1119efe04852fa732@sentry.rapidcompact.link/5',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  transport: Sentry.makeBrowserOfflineTransport(makeFetchTransport),
  integrations: [Sentry.browserTracingIntegration()],
  ignoreErrors: ['INVALID_CALLBACK_URL_ERROR'],
});
